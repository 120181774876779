import GoogleAnalyticsService from "../GoogleAnalyticsService";
import * as Sentry from "@sentry/browser";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import { Environment } from "@/enums/Config";

const ganalytics = new GoogleAnalyticsService();

jest.mock("@/services/MyRentalsConfig");

beforeEach(() => {
  jest.restoreAllMocks();
  spyOn(Sentry, "captureException");

  window.dataLayer = [];
});

describe("GoogleAnalyticsService", () => {
  describe("trackEvent", () => {
    it("should call trackEvent triggerGoal2 method with correct params", () => {
      // given
      spyOn(console, "info");
      // when
      ganalytics.trackRNTLeadOwnerGoal2();
      // then
      expect(console.info).toHaveBeenCalledWith(
        `[Google Analytics] TrackObjective rental lead owner`
      );
    });

    it("should call trackEvent triggerGoal4 method with correct params", () => {
      // given
      spyOn(console, "info");
      // when
      ganalytics.trackRNTLeadViableGoal4();
      // then
      expect(console.info).toHaveBeenCalledWith(
        `[Google Analytics] TrackObjective lead_rental NUE_rental null`
      );
    });

    it("should not console log anything if environment is production", () => {
      spyOn(MyRentalsConfig, "env").and.returnValue({
        ENVIRONMENT: Environment.PROD,
      });
      spyOn(console, "info");
      spyOn(console, "error");

      // when
      ganalytics.trackRNTLeadOwnerGoal2();
      ganalytics.trackRNTLeadViableGoal4();
      // then

      expect(console.info).not.toHaveBeenCalled();
      expect(console.error).not.toHaveBeenCalled();
    });
  });
});
